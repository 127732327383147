import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/firestore'

const config = {
  apiKey: 'AIzaSyBiAsq505ADhBbnDKXhNN2tK9Mn05tQgNY',
  authDomain: 'credits-portal.firebaseapp.com',
  databaseURL: 'https://credits-portal.firebaseio.com',
  projectId: 'credits-portal',
  storageBucket: 'credits-portal.appspot.com',
  messagingSenderId: '271183933497',
  appId: '1:271183933497:web:21eb00e540479579065de8',
}
firebase.initializeApp(config)

const { auth } = firebase
const db = firebase.firestore()
const functions = firebase.functions()

export { auth, functions, db }
