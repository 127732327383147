import React, { useState } from 'react'
import { auth } from '../firebase'
import { Input, Button, Typography, message } from 'antd'
import logo from '../images/2019-02-logo-principal-digitalway.svg'
import '../Login.css'

const { Title } = Typography

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    try {
      await auth().signInWithEmailAndPassword(email, password)
    } catch (e) {
      message.error(e.message)
      setIsLoading(false)
    }
  }

  return (
    <div className="loginContainer">
      <div className="loginCard">
        <div className="loginTitle">
          <img src={logo} alt="digital way logo" className="loginLogo" />
          <Title
            style={{
              color: '#051937',
              fontFamily: 'Poppins',
              textAlign: 'center',
              fontSize: '4.5vh',
              marginLeft: '1rem',
              marginBottom: 0,
              padding: '1rem',
              borderLeft: '1px solid #051937',
            }}
          >
            Credits Portal
          </Title>
        </div>
        <form onSubmit={e => handleSubmit(e)}>
          <Input
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="loginInput"
            size="large"
          />
          <Input.Password
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
            className="loginInput"
            size="large"
          />
          <Button
            loading={isLoading}
            block
            type="primary"
            htmlType="submit"
            className="loginInput"
            size="large"
          >
            Login
          </Button>
        </form>
        <p style={{ textAlign: 'center' }}>If you are here, you are VIP</p>
      </div>
    </div>
  )
}

export default Login
