import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import Login from './components/Login'
import { BrowserRouter as Router } from 'react-router-dom'
import { auth } from './firebase'
import * as serviceWorker from './serviceWorker'

const root = document.getElementById('root')

//Render app with routing
const renderApp = user => {
  ReactDOM.render(
    <Router>
      <App currentUser={user} />
    </Router>,
    document.getElementById('root')
  )
}

//render login if user is not auth
const renderLogin = () => {
  ReactDOM.render(<Login />, root)
}

auth().onAuthStateChanged(user => {
  console.log('on Auth State changed')
  if (user) {
    renderApp(user)
  } else {
    renderLogin()
  }
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
