import React from 'react'
import { Row, Col } from 'antd'
import formatTime from '../utils/formatTimestamp'

const Expense = ({ expense }) => {
  const { credits, description, date, type } = expense
  return (
    <div className="expenseContainer">
      <Row align="middle">
        <Col span={4}>
          <div className="expenseCredits">
            {type === 'buy' ? '+' : '-'}
            {credits}
          </div>
        </Col>
        <Col span={12}>{description}</Col>
        <Col span={8}>{formatTime(date)}</Col>
      </Row>
    </div>
  )
}

export default Expense
