import React, { useEffect, useState, useCallback } from 'react'
import { db } from '../firebase'
import { Empty, Button } from 'antd'
import Expense from './Expense'
import Loader from './Loader'

const History = ({ currentUser }) => {
  const [expenses, setExpenses] = useState([])
  const [lastExpense, setLastExpense] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [disableLoadMore, setDisableLoadMore] = useState(false)

  const loadMoreExpenses = useCallback(
    async startAfter => {
      const limit = 5
      let getQuery
      if (startAfter) {
        getQuery = db
          .collection('users')
          .doc(currentUser.uid)
          .collection('expenses')
          .orderBy('date', 'desc')
          .startAfter(startAfter)
          .limit(limit)
      } else {
        getQuery = db
          .collection('users')
          .doc(currentUser.uid)
          .collection('expenses')
          .orderBy('date', 'desc')
          .limit(limit)
      }
      try {
        const query = await getQuery.get()
        console.log('called fetch history')
        if (query.docs) {
          const moreExpenses = query.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))
          setExpenses(exp => [...exp, ...moreExpenses])
          setLastExpense(query.docs[query.docs.length - 1])
          if (query.docs.length < limit) setDisableLoadMore(true)
        }
        setIsLoading(false)
      } catch (e) {
        console.log(e.message)
      }
    },
    [currentUser.uid]
  )

  useEffect(() => {
    loadMoreExpenses()
  }, [loadMoreExpenses])

  return (
    <div className="historyCard">
      <h1>Credits history:</h1>
      <p>Credits you've purchased and used over time.</p>
      <div>
        {isLoading ? (
          <div className="expensesLoader">
            <Loader />
          </div>
        ) : (
          <>
            {expenses.length === 0 && <Empty description="No expenses yet" />}
            {expenses.map(expense => (
              <Expense key={expense.id} expense={expense} />
            ))}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {!disableLoadMore ? (
                <Button
                  size="small"
                  onClick={() => loadMoreExpenses(lastExpense)}
                >
                  Load more
                </Button>
              ) : (
                <small>That's all your history</small>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default History
