import React from 'react'
import { Row, Col } from 'antd'
import Credits from './Credits'
import History from './History'

const Home = ({ currentUser }) => {
  return (
    <div>
      <h1 style={{ wordBreak: 'break-word', marginBottom: 0 }}>
        Hi {currentUser.email && currentUser.email.split('@')[0]}
      </h1>
      <small>{currentUser.email}</small>
      <p style={{ marginBottom: '2rem', marginTop: '1rem' }}>
        Welcome <strong>Digital Way's credits portal</strong>. VIP clients can
        purchase credits in advance to access lower rates in specialized
        services
      </p>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={10}
          lg={10}
          xl={10}
          style={{ marginBottom: '2rem' }}
        >
          <Credits currentUser={currentUser} />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={14}
          lg={14}
          xl={14}
          style={{ marginBottom: '2rem' }}
        >
          <History currentUser={currentUser} />
        </Col>
      </Row>
    </div>
  )
}

export default Home
