import React, { useEffect, useState } from 'react'
import { db } from '../firebase'
import { Typography, Button, Divider } from 'antd'
import Loader from './Loader'
import SelectCreditsAmount from './SelectCreditsAmount'

const { Title } = Typography

const Credits = ({ currentUser }) => {
  const [credits, setCredits] = useState(0)
  const [price, setPrice] = useState(60)
  const [normalRate, setNormalRate] = useState(60)
  const [currency, setCurrency] = useState('USD')
  const [isLoading, setIsLoading] = useState(true)
  const [openSelectCredits, setOpenSelectCredits] = useState(false)

  useEffect(() => {
    const unsubscribeToExpenses = db
      .collection('users')
      .doc(currentUser.uid)
      .onSnapshot(doc => {
        const userCredits = doc.data()
        if (userCredits) {
          setCredits(userCredits.credits || 0)
          setPrice(userCredits.preferentialRate || 60)
          setNormalRate(userCredits.normalRate || 60)
          setCurrency(userCredits.currency || 'USD')
        }
        setIsLoading(false)
      })
    return () => unsubscribeToExpenses()
  }, [currentUser])

  return (
    <div className="creditsCard">
      <h1>You currently have:</h1>
      <Title
        style={{
          // color: '#fff',
          fontFamily: 'Poppins',
          fontSize: '7.5vh',
          marginBottom: 0,
          borderLeft: '2px solid #fff',
        }}
      >
        {isLoading ? <Loader size={48} /> : credits}
      </Title>
      <h1>Credits</h1>
      <Divider />
      {!isLoading && (
        <>
          <p>
            Each credit is worth {currency} {price}
          </p>
        </>
      )}
      <Button
        onClick={() => setOpenSelectCredits(true)}
        type="primary"
        block
        size="large"
      >
        Buy more
      </Button>
      <div style={{ textAlign: 'center', marginTop: '0.5rem' }}>
        <small>
          Save {currency} {normalRate - price} worth of specialized services on
          each credit
        </small>
      </div>
      <SelectCreditsAmount
        currentUser={currentUser}
        currency={currency}
        price={price}
        isVisible={openSelectCredits}
        setIsVisible={setOpenSelectCredits}
      />
    </div>
  )
}

export default Credits
