import React from 'react'
import { Switch, Route } from 'react-router-dom'
import './App.css'
import { Layout, Divider } from 'antd'
import Header from './components/Header'
import Home from './components/Home'
import PaymentStatus from './components/PaymentStatus'
import logo from './images/2019-02-logo-principal-digitalway.svg'
const { Footer } = Layout

function App({ currentUser }) {
  return (
    <>
      <Header />
      <main className="App">
        <Switch>
          <Route exact path="/">
            <Home currentUser={currentUser} />
          </Route>
          <Route exact path="/payment-status/">
            <PaymentStatus currentUser={currentUser} />
          </Route>
        </Switch>
      </main>
      <Footer
        style={{
          textAlign: 'center',
          backgroundColor: '#f5f5f5',
          marginTop: '3rem',
          paddingTop: '3rem',
        }}
      >
        <img src={logo} alt="logo" width="100px" />
        <p style={{ marginTop: 10 }}>hey@digitalway.guru</p>
        <Divider />
        <div>Digital Way Guru {new Date().getFullYear()}.</div>
      </Footer>
    </>
  )
}

export default App
