import React, { useEffect, useState } from 'react'
import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'
import Loader from './Loader'
import axios from 'axios'

const PaymentStatus = () => {
  const [paymentStatus, setPaymentStatus] = useState(7)
  const [amount, setAmount] = useState(0)
  const [currency, setCurrency] = useState('USD')
  const [transactionId, setTransactionId] = useState('')
  const [credits, setCredits] = useState(0)
  const [loading, setLoading] = useState(true)

  function getStatusInfo(status) {
    switch (status) {
      case 1:
        return {
          state: 'success',
          title: `Successfully Purchased ${credits} credits for ${currency} ${amount}. ID: ${transactionId}`,
        }
      case 2:
        return {
          state: 'error',
          title: `Transaction rejected, please contact your bank for further info. No charges were made. ID: ${transactionId}`,
        }
      case 3:
        return {
          state: 'warning',
          title: `Transaction pending. Our payment processor is making some validation, you will receive an confirmation email once everything is veirify. ID: ${transactionId}`,
        }
      case 4:
        return {
          state: 'error',
          title: `Transaction failed, please contact your bank for further info. No charges were made. ID: ${transactionId}`,
        }
      case 6:
        return {
          state: 'error',
          title: `Transaction reversed, please contact your bank for further info. ID: ${transactionId}`,
        }
      case 7:
        return {
          state: 'info',
          title: `Transaction started. ID: ${transactionId}`,
        }
      case 8:
        return {
          state: 'error',
          title: `Transaction retain, please contact your bank for further info. ID: ${transactionId}`,
        }
      case 9:
        return {
          state: 'error',
          title: `Transaction expired, please contact your bank for further info. ID: ${transactionId}`,
        }
      case 10:
        return {
          state: 'error',
          title: `Transaction abandoned, please contact your bank for further info. ID: ${transactionId}`,
        }
      case 11:
        return {
          state: 'error',
          title: `Transaction canceled, please contact your bank for further info. ID: ${transactionId}`,
        }
      case 12:
        return {
          state: 'warning',
          title: `Transaction in anti-fraud state. Once is finished, the confirmation will come to your email. ID: ${transactionId}`,
        }
      default:
        return {
          state: 'error',
          title: `We could not identify the state of your transaction, please contact us and provide the following ID: ${transactionId}`,
        }
    }
  }

  useEffect(() => {
    const getPaymentData = async url => {
      try {
        const response = await axios.get(url)
        console.log(response.data.data)
        if (response.data.data) {
          setPaymentStatus(response.data.data.x_cod_transaction_state)
          setAmount(response.data.data.x_amount)
          setCurrency(response.data.data.x_currency_code)
          setTransactionId(response.data.data.x_transaction_id)
          setCredits(response.data.data.x_extra3)
        }
        setLoading(false)
      } catch (e) {
        console.log(e)
      }
    }
    const urlParams = new URLSearchParams(window.location.search)
    const refPayco = urlParams.get('ref_payco')
    const urlapp =
      'https://secure.epayco.co/validation/v1/reference/' + refPayco

    getPaymentData(urlapp)
  }, [])

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Loader size={48} />
        </div>
      ) : (
        <Result
          status={getStatusInfo(paymentStatus).state}
          title={getStatusInfo(paymentStatus).title}
          subTitle={`You will receive a confirmation email. If you pay via bank transfer, the credits and the invoice will take arround 5 - 10 minutes to appear`}
          extra={[
            <Link to="/" key="button">
              <Button size="large" type="primary" key="console">
                Go To Dashboard
              </Button>
            </Link>,
          ]}
        />
      )}
    </div>
  )
}

export default PaymentStatus
