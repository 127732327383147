import React, { useState, useEffect } from 'react'
import {
  Button,
  Modal,
  Radio,
  Row,
  Col,
  Typography,
  InputNumber,
  message,
} from 'antd'
import {
  SmileOutlined,
  LikeOutlined,
  HeartOutlined,
  RocketOutlined,
  FireOutlined,
  CrownOutlined,
} from '@ant-design/icons'
import axios from 'axios'

const { Title } = Typography

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
}

const IconFeedback = ({ value }) => {
  switch (value) {
    case 1:
      return <SmileOutlined className="iconFeedback" />
    case 2:
      return <LikeOutlined className="iconFeedback" />
    case 3:
      return <HeartOutlined className="iconFeedback" />
    case 4:
      return <RocketOutlined className="iconFeedback" />
    case 5:
      return <FireOutlined className="iconFeedback" />
    default:
      return <CrownOutlined className="iconFeedback" />
  }
}

const SelectCreditsAmount = ({
  currentUser,
  isVisible,
  setIsVisible,
  currency,
  price,
}) => {
  const [creditsAmount, setCreditsAmount] = useState(1)
  const [customCreditsAmount, setCustomCreditsAmount] = useState(6)
  const [country, setCountry] = useState('US')
  const [total, setTotal] = useState(price)
  const [totalBackground, setTotalBackground] = useState('background1')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getCountry = async () => {
      const response = await axios.get('https://ip2c.org/s')
      const countryCode = response.data.split(';')[1]
      localStorage.setItem('country', countryCode)
      setCountry(countryCode || 'US')
      console.log('called get country')
    }

    const localCountry = localStorage.getItem('country')
    if (localCountry) {
      setCountry(localCountry || 'US')
    } else {
      getCountry()
    }
  }, [])

  useEffect(() => {
    if (creditsAmount >= 6) {
      setTotal(customCreditsAmount)
      setTotalBackground('background6')
    } else {
      setTotal(creditsAmount)
      if (creditsAmount === 1) setTotalBackground('background1')
      if (creditsAmount === 2) setTotalBackground('background2')
      if (creditsAmount === 3) setTotalBackground('background3')
      if (creditsAmount === 4) setTotalBackground('background4')
      if (creditsAmount === 5) setTotalBackground('background5')
    }
  }, [creditsAmount, price, customCreditsAmount])

  const openCheckout = () => {
    if (!total || total === 0) return message.error('please select an amount')
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 4000)
    const handler = window.ePayco.checkout.configure({
      key: 'cdb0d0cdfc4f9bc600b8eac41bd792c6',
      test: false,
    })

    const data = {
      //Parametros compra (obligatorio)
      name: `${total} Digital Way Credits`,
      description: `${total} Credits for lower rate`,
      invoice: 'inv_' + total + '_' + Date.now() + '_' + currentUser.uid,
      currency: currency,
      amount: total * price,
      tax_base: '0',
      tax: '0',
      lang: 'en',
      country,
      //user uuid for db update
      extra1: currentUser.uid,
      //user email, in case the user change the payment email
      extra2: currentUser.email,
      //credits amount
      extra3: total,
      //Onpage="false" - Standard="true"
      external: 'false',

      //Atributos opcionales
      response: 'https://credits.digitalway.guru/payment-status',

      //Atributos cliente
      email_billing: currentUser.email,

      //atributo deshabilitación metodo de pago
      methodsDisable: ['CASH', 'SP', 'DP'],
    }
    handler.open(data)
  }
  return (
    <Modal
      visible={isVisible}
      style={{ borderRadius: '10px' }}
      centered
      title="How many credits do you want to buy?"
      // onOk={this.handleOk}
      onCancel={() => setIsVisible(false)}
      footer={[
        <Button key="back" size="large" onClick={() => setIsVisible(false)}>
          Cancel
        </Button>,
        <Button
          loading={loading}
          key="submit"
          type="primary"
          size="large"
          onClick={() => openCheckout()}
        >
          Pay now
        </Button>,
      ]}
    >
      <Row>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          style={{ marginBottom: '2rem' }}
        >
          <Radio.Group
            onChange={e => setCreditsAmount(e.target.value)}
            value={creditsAmount}
          >
            <Radio style={radioStyle} value={1}>
              <strong>1 credit</strong>
            </Radio>
            <Radio style={radioStyle} value={2}>
              <strong>2 credits</strong>
            </Radio>
            <Radio style={radioStyle} value={3}>
              <strong>3 credits</strong>
            </Radio>
            <Radio style={radioStyle} value={4}>
              <strong>4 credits</strong>
            </Radio>
            <Radio style={radioStyle} value={5}>
              <strong>5 credits</strong>
            </Radio>
            <Radio style={radioStyle} value={6}>
              <strong>Custom...</strong>
            </Radio>
          </Radio.Group>
          {creditsAmount >= 6 ? (
            <InputNumber
              min={6}
              max={100}
              defaultValue={6}
              value={customCreditsAmount}
              onChange={value => setCustomCreditsAmount(value)}
              style={{ width: 100, marginLeft: 10 }}
            />
          ) : null}
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div
            className={`totalCreditsCard ${totalBackground}`}
            style={{
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <div>
              <h1>Total:</h1>
              <Title
                style={{
                  fontFamily: 'Poppins',
                  fontSize: '4.5vh',
                  marginBottom: 0,
                  color: '#fff',
                }}
              >
                {total * price} {currency}
              </Title>
              <IconFeedback value={creditsAmount} />
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default SelectCreditsAmount
